<template>
  <div class="pl-3 pt-3">
    <Header @back-to-roles="backToRoles" />

    <v-card
      class="py-6 px-6 mt-3 app-height-global"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <v-form
        ref="form"
        v-model="valid"
      >
        <div class="d-flex justify-space-between align-center flex-row mb-5">
          <div class="w-full mr-3">
            <div class="text-start mb-2">
              <v-label class="text-theme-label font-weight-medium">
                {{ $t('name') }}
              </v-label>
            </div>
            <v-text-field
              v-model="roleName"
              full-width
              :placeholder="$t('name')"
              class="custom_input round-8 pl-3"
              dense
              height="38px"
              :rules="nameRules"
              required
            />
          </div>
          <div class="w-full ml-3">
            <div class="text-start mb-2">
              <v-label class="text-theme-label font-weight-medium">
                {{ $t('description') }}
              </v-label>
            </div>
            <v-text-field
              v-model="roleDescription"
              full-width
              :placeholder="$t('description')"
              class="custom_input round-8 pl-3"
              dense
              height="38px"
            />
          </div>
          <div class="w-full ml-3">
            <p class="text-body-2">
              {{ $t('tags') }}
            </p>
            <div class="d-flex">
              <TagSelector
                v-model="roleTags"
                :items="tags"
                class=""
              />
            </div>
          </div>
        </div>
        <div>
          <h4 class="medium mb-5">
            Permission
          </h4>
          <div
            v-for="(item, index) in items"
            :key="index"
          >
            <template v-if="item.type == 1">
              <CheckboxPanel 
                :items="item.actions" 
                :parent-label="item.name"
                :description="item.description"
                :initial="roleDetail?.permissions" 
                @update-items="(updatedItems) => checkboxUpdate(updatedItems, index)"
              />
            </template>
            <template v-else-if="item.type == 2">
              <NestedCheckboxPanel
                :items="item.actions"
                :parent-label="item.name"
                :description="item.description"
                :initial="roleDetail?.permissions" 
                @update-items="(updatedItems) => nestedCheckboxUpdate(updatedItems, index)"
              />
            </template>
            <template v-else>
              <v-expansion-panels
                mandatory
                flat
              >
                <v-expansion-panel>
                  <v-expansion-panel-header class="pa-0">
                    <div class="d-flex justify-start align-center">
                      <div class="cw-custom">
                        <p class="text-body-2 mb-0">
                          {{ item.name }}
                        </p>
                      </div>
                      <p class="text-body-2 mb-0 grey--text ml-custom">
                        {{ item.description }}
                      </p>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-radio-group
                      v-model="billing"
                      mandatory
                    >
                      <div
                        v-for="(_item) in item.actions"
                        :key="_item.id"
                        class="d-flex justify-start align-center"
                        style="height: 66px;"
                      >
                        <v-radio
                          :label="_item.name"
                          :value="_item.value"
                          class="mb-1 cw-custom"
                          @change="onRadioChange(_item.value, index)"
                        />
                        <p class="text-body-2 mb-0 grey--text ml-custom">
                          {{ _item.description }}
                        </p>
                      </div>
                    </v-radio-group>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>
          </div>
        </div>
        <div class="d-flex justify-end">
          <v-btn
            :disabled="!valid"
            color="primary"
            class="mr-4"
            :loading="loading"
            @click="validate"
          >
            Save changes
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import makeRoleService from '@/services/api/role';
import Header from '@/components/Admin/Role/HeaderEdit';
import { permissions } from '@/constants/rolePermissions';
import CheckboxPanel from '@/components/Project/CheckboxPanel.vue';
import NestedCheckboxPanel from '@/components/Project/NestedCheckboxPanel.vue';
import makeTagService from "@/services/api/tag";
import TagSelector from "@/components/base/TagSelector.vue";

const { mapState } = createNamespacedHelpers('user');

export default {
    name: 'EditRoleView',

    components: {
        Header,
        CheckboxPanel,
      NestedCheckboxPanel,
        TagSelector
    },

    data() {
        return {
            valid: false,
            roleName: '',
            roleDescription: '',
            items: [],
            billing: null,
            selected: [],
            loading: false,
            roleDetail: {
                permissions: []
            },
            nameRules: [
                v => !!v || 'Name is required'
            ],
            roleTags: [],
            tags: [],
        };
    },
    methods: {
        backToRoles() {
            this.$router.push({ name: 'Roles' });
        },
        checkboxUpdate(updatedItems, index) {
            const existingItemIndex = this.selected.findIndex(item => item.id === index);
            if (existingItemIndex !== -1) {
                this.selected[existingItemIndex].items = updatedItems;
            } else {
                this.selected.push({
                    id: index,
                    items: updatedItems
                });
            }
        },
        nestedCheckboxUpdate(updatedItems, index) {
            const existingItemIndex = this.selected.findIndex(item => item.id === index);
            if (existingItemIndex !== -1) {
                this.selected[existingItemIndex].items = updatedItems;
            } else {
                this.selected.push({
                    id: index,
                    items: updatedItems
                });
            }
        },
        onRadioChange(value, index) {
            const existingItemIndex = this.selected.findIndex(item => item.id === index);
            if (existingItemIndex !== -1) {
                this.selected[existingItemIndex].items = value;
            } else {
                this.selected.push({
                    id: index,
                    items: value
                });
            }
        },
        validate() {
            if (this.$refs.form.validate()) {
                const roleService = makeRoleService(this.$api);
                const id = this.$route.params.id;
                this.loading = true;
                roleService.updateRole(this.currentAccount.handle, {
                    name: this.roleName,
                    description: this.roleDescription,
                    permissions: this.flattenedSelectedItems,
                    tagUids: this.roleTags.map(tag => tag.uid)
                }, id).then(() => {
                    this.$router.push({ name: 'Roles' });
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        async getRoleDetail(handle) {
            const id = this.$route.params.id;
            const roleService = makeRoleService(this.$api);
            // this.isLoading = true
            try {
                const response = await roleService.getRoleDetails(handle, id);
                this.roleDetail.permissions = response.data?.permissions;
                this.roleName = response.data?.name;
                this.roleDescription = response.data?.description;
                this.roleTags = response.data?.tags?.map(tag => ({ uid: tag.uid, name: tag.name }));
            } catch (err) {
                // this.isLoading = true
                this.errorMessage = err.message;
            }
        },
        async fetchTags() {
            const tagService = makeTagService(this.$api);
            try {
                const response = await tagService.getTags(this.currentAccount.handle, 'users');
                if (response.status === 200) {
                    this.tags = response.data.map((tag) => ({ uid: tag.uid, name: tag.name }));
                }
            } catch (error) {
                console.error("Error fetching tags:", error);
            }
        },
    },
    computed: {
        ...mapState(['currentAccount']),
        flattenedSelectedItems() {
            const data = this.selected.map(item => item.items);
            return data.flat(1);
        },
        billingPermissionText() {
            if (this.roleDetail.permissions.includes('full_billing')) {
                return 'full_billing';
            } else if (this.roleDetail.permissions.includes('limited_billing')) {
                return 'limited_billing';
            } else if (this.roleDetail.permissions.includes('no_billing')) {
                return 'no_billing';
            } else {
                return undefined;
            }
        }
    },
    async mounted() {
        this.items = permissions;
        await this.getRoleDetail(this.currentAccount.handle);
        if(this.billingPermissionText) {
            this.billing = this.billingPermissionText;
        } else {
            this.billing = null;
            this.selected.push({
                id: 14,
                items: "full_billing"
            });
        }
        this.fetchTags();
    },
}
</script>

<style scoped>
.custom_input {
    background-color: #f9f9fb;
    height: 38px;
}

.ml-custom {
    margin-left: 20rem;
}

.cw-custom {
    width: 100%;
    max-width: 160px;
}
</style>
